<template>
  <div
    class="columns is-multiline is-marginless has-padding-50 has-padding-bottom-400"
  >
    <div class="column is-12">
      <collapse title="Order">
        <order :order-id="orderId" />
      </collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminOrder",
  components: {
    order: () => import("@shared/orders/_order")
  },
  data() {
    return {
      orderId: this.$route.params.orderId
    };
  },
  beforeDestroy() {
    this.$store.dispatch("orders/unobserveOrder", {
      orderId: this.orderId
    });
  },
  created() {
    this.$store
      .dispatch("orders/observeOrder", { orderId: this.orderId })
      .catch(() => {
        this.orderNotFound();
      });
  },
  methods: {
    orderNotFound() {
      this.$router.push(`/admin/orders`);
      this.$toast.open({
        message: `Order not found`,
        type: "is-danger",
        position: "is-bottom"
      });
    }
  }
};
</script>
